import React from 'react';
import PlatformFooter from 'cccisd-footer';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

export const otherLanguages = [
    { label: 'English', url: Boilerplate.url('') },
    { label: 'Español', url: 'https://es.helpisinyourhands.org' },
    // { label: '日本語', url: 'https://jp.helpisinyourhands.org' },
];

const Footer = ({ className }) => {
    return (
        <div className={style.footer}>
            <div className={className}>
                <ul className={'list-inline ' + style.languages}>
                    {otherLanguages.map((lan, i) => {
                        return (
                            <li key={lan.label + i}>
                                <a href={lan.url}>{lan.label}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <PlatformFooter className={className} />
        </div>
    );
};

export default Footer;
