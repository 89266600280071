import React from 'react';
import style from './style.css';

const SiteUpgradeCallout = ({ className }) => {
    return (
        <div className={style.siteUpgradeCallout}>
            <div className={className}>
                <p>
                    Welcome to the new <b>Help is in Your Hands</b> site! If you had a previous account, you can{' '}
                    <a href="https://legacy.helpisinyourhands.org" target="_blank" rel="noopener noreferrer">
                        access your old account here
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default SiteUpgradeCallout;
