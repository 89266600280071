import React from 'react';
import _find from 'lodash/find';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'cccisd-react-router';
import logo3c from './logo_3c.png';
import logoEsdm from './logo_esdm.jpg';
import logoIes from './logo_ies.png';
import logoUcdavis from './logo_ucdavis.png';
import { otherLanguages } from '../../components/Footer';
import style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className={'row ' + style.wrapper}>
            {/* Login Form should be on top in mobile, but on right side in desktop */}
            <div className={'col-md-4 ' + style.mobileForm}>
                {Fortress.auth() ? (
                    <div className={style.welcomeBox}>
                        <p>Hello, {Fortress.user.username}</p>
                        {homepageRoute && (
                            <Link to={homepageRoute.url}>
                                <button type="button" className="btn btn-primary">
                                    Go To Home
                                </button>
                            </Link>
                        )}
                    </div>
                ) : (
                    <LoginForm showPasswordToggle />
                )}
            </div>

            <div className="col-md-7">
                <h2>Welcome to Help is in Your Hands</h2>
                <div className={style.logos}>
                    <img src={logoEsdm} alt="ESDM" style={{ minWidth: 60, maxWidth: 80 }} />
                    <img
                        src={logoUcdavis}
                        alt="UC Davis Health | Mind Institute"
                        style={{ minWidth: 160, maxWidth: 200 }}
                    />
                    <img src={logoIes} alt="Institute of Education Sciencs" style={{ minWidth: 140, maxWidth: 180 }} />
                    <img src={logo3c} alt="3C Institute" style={{ minWidth: 80, maxWidth: 100 }} />
                </div>
                <div className={style.originalInfo}>
                    <p>
                        <b>Help Is In Your Hands</b> is a product of the C-ESDM Project Developed by Sally Rogers and
                        Aubyn Stahmer
                    </p>

                    <p>
                        With support from the Institute of Education Sciences, the MIND Institute, and the 3C Institute.
                    </p>

                    <p>
                        In collaboration with research and community partnerships at the University of Pennsylvania,
                        Children’s Hospital Colorado, and The University of Alabama.
                    </p>

                    <p>
                        The research reported here was supported by the Institute of Education Sciences, U.S. Department
                        of Education Grant #R324A150211, through the University of California, Davis.
                    </p>

                    <p>
                        The opinions expressed are those of the authors and do not represent views of the Institute or
                        the U.S. Department of Education.
                    </p>
                </div>
                <div className="alert alert-info">
                    <p>
                        <b>Help is in Your Hands</b> is available in these languages:
                    </p>
                    <ul className={style.otherLanguages}>
                        {otherLanguages.map(lan => (
                            <li key={lan}>
                                <a href={lan.url}>{lan.label}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="col-md-1" />
            <div className={'col-md-4 ' + style.desktopForm}>
                {Fortress.auth() ? (
                    <div className={style.welcomeBox}>
                        <p>Hello, {Fortress.user.username}</p>
                        {homepageRoute && (
                            <Link to={homepageRoute.url}>
                                <button type="button" className="btn btn-primary">
                                    Go To Home
                                </button>
                            </Link>
                        )}
                    </div>
                ) : (
                    <>
                        <LoginForm showPasswordToggle />
                        <div className={style.createNewAccount}>
                            <Link to="/login">Need an account?</Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Welcome;
