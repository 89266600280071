import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';

const Register = () => {
    const props = {
        loginProps: {},
        registerProps: {
            role: 'learner',
            showPasswordFields: true,
        },
    };

    return <RegisterLoginPage {...props} />;
};

export default Register;
