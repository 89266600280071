import React from 'react';
import PropTypes from 'prop-types';

import Header from 'cccisd-header';

import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const NavBar = ({ className, noLogoLink }) => {
    let primaryNavLinks = [];
    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
    } catch (e) {
        // meh, leave it empty
    }

    const isSiteAdmin = Fortress.user.acting.role.handle === 'siteAdmin';

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logoPlaceholder}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            showHomeAction={!Fortress.auth()}
            showAdminAction={!isSiteAdmin}
            {...(noLogoLink ? { logoLink: null } : {})}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
